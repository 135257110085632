.venueImageCard{

  &:hover{
    .venueImageCardOverlay{
        display: block;
        background-color: #222222;
    }
  }

}

.venueImageCardOverlay{
  display: none;
}